.footer {
  width: 100%;
  height: auto;
  display: grid;
  padding: 2rem 0rem 4rem 0rem;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  color: rgb(255, 255, 255);
  gap: 1rem;
  

  .s_i {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    img {
      width: 150px;
    }
    small{
      font-size: 13px;
    }
  }
  .s_ii {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 20px;
    h5 {
      margin-bottom: 20px;
      color: var(--brown);
    }
    ul {
      li {
        margin-bottom: 10px;
        font-size: 14px !important;
        list-style: none;
      }
      li:hover{
        color: var(--brown);
      }
    }
  }
  .s_iii {
     display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 20px;
    h5 {
      margin-bottom: 20px;
      color: var(--brown);
    }
  }
  .s_iv {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 20px;
    .so{
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 30px;
        margin-bottom: 20px;
        
    }
    h5 {
      margin-bottom: 20px;
      color: var(--brown);
    }
  }
  span{
    font-size: 13px;
  
}
}
@media screen and (max-width: 900px) {
  .footer {
    grid-template-columns: 1fr;
  }
}

