.journal_wrp {
  width: 100%;
  padding: 2rem 0rem;
  h5{
        color: var(--blue);
    }
  .journalCards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .card {
        margin-bottom: 2rem;
      padding: 10px;
      width: 350px;
      .img {
        margin-bottom: 10px;
        display: flex;
        .img_in {
          overflow: hidden;
          img {
            width: 150px;
          }
        }
      }
      .p_dec {
        display: flex;
        flex-direction: column;
        span{
            margin-top: 10px;
            font-size: 14px;
            text-decoration: underline;
            cursor: pointer;

        }
      }
    }
  }
}
