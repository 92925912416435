.copy_wrp{
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    small{
        color: white;
        font-size: 11px;

    
    }
}