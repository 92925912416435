.slideShow {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  // height: 400px;
  .owl-theme {
    background-position: bottom;
    .item {
      width: 100%;
      height: auto !important;
      object-fit: cover;
      position: relative;
      align-items: center;
      justify-content: center;
      display: flex;
      img {
        overflow: hidden;
        width: 100% !important;
        height: auto;
      }
      .h_text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 50px;
        right: 0;
        flex-direction: column;
        padding: 0px 2px;
        h5{
          text-align: center;
color: white;
        }
        p{
          text-align: center;
          color: var(--brown);
        }
      }
      @media screen and (max-width: 900px) {
        .h_text {
          bottom: 5px;
          h5 {
            font-size: 14px;
            display: none;
          }
          p{
            font-size: 12px;
            display: none;
          }
        }
      }
    }
  }
}
