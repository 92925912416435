.about_wrp {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0rem;

  .about_left {
    width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;
    align-items: center;
    .about_img {
      display: flex;
      overflow: hidden;
      border-radius: 0rem 0rem 10rem 10rem;
      img {
        width: 100%;
      }
    }
  }
  .about_right {
    width: 100%;
    height: auto;
    margin-top: 3rem;
    .r_top {
      h3 {
        font-weight: 300;
        color: var(--brown);
        em {
          color: black;
          font-weight: 600;
          font-style: normal;
          margin: 0px 10px;
        }
      }
    }
    .r_down {
      margin-top: 2rem;
    }
  }
}
.why_chu {
  width: 100%;
  margin: 3rem 0rem;
}
@media screen and (max-width: 900px) {
  .about_wrp {
    grid-template-columns: 1fr;
  }
}
