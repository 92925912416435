.publication_wrp {
  width: 100%;
  padding: 2rem 0rem;
  h5 {
    color: var(--blue);
  }

  .list_wrp {
    width: 100%;
    margin-top: 2rem;
    h6{
        margin-bottom: 1rem;
    }
    ul {
      margin-left: 2rem;
      li {
        font-size: 14px;
        margin-bottom: 0.5rem;
        em {
          color: #00afef;
          font-style: normal;
        }
      }
    }
  }
}
