.NavbarItems {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
}

.MenuItems {
  list-style: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  gap: 30px;
}
.nv {
  font-weight: 600;
  transition: var(--tran);
  font-size: 14px;
  margin-bottom: 10px;


}
.nv:hover {
  color: var(--brown);
  transition: var(--tran);
  
}
.subNav{
  position:relative;

}
.dropDown{
  position: absolute;
  background-color: white;
  z-index: 999;
  padding: 10px 20px;
  display: flex;
  align-items:flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.dropDown li{
  font-size: 13px !important;
  margin-bottom: 12px;
  font-weight: 400;
  
}
.dropDown li:hover{
  text-decoration: underline;
  
}


.nv:focus {
  color: var(--brown);
}

.nav-links {
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 10px 12px;
  
}

.nav-links i {
  padding-right: 10px;
  

}
.nav-links:hover {
  /* background-color: rgb(181, 181, 181); */
  color: var(--brown) !important;
  transition: all 0.2s ease-in-out;
}

.Hamburger-Cross-Icons {
  cursor: pointer;
  display: none;
}
.Logo_wrp {
  display: flex;
  width: 100%;
}
.logo_img {
  width: 200px;
  margin-top: 5px;
}

.nav-button {
  margin-left: 10px;
  padding: 6px 12px;
  background-color: var(--brown);
  border-radius: 5px;
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid var(--brown);
  transition: var(--tran);
}
.nav-button:hover {
  transition: var(--tran);
  background-color: var(--tBg);
  color: var(--brown) !important;
  border: 1px solid var(--brown);
}

@media screen and (max-width: 1000px) {
  .NavbarItems {
    z-index: 99;
  }

  .MenuItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*background-color: rgba(700, 225, 15, 0.55);*/
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: auto;
    backdrop-filter: blur(5px);
    position: absolute;
    align-items: stretch;
    top: 80px;
    left: -110%;
    padding: 2rem;
    margin: 0;
    margin-top: -10px;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }
  .MenuItems.active {
    left: 0%;
  }
  .nav-links {
    display: block;
    width: 100%;
    padding: 1.5rem 0;
  }
  .Hamburger-Cross-Icons {
    cursor: pointer;
    display: block;
  }
  .nav-button {
    padding: 1rem;
    display: block;
    text-align: center;
    width: 80%;
    margin: auto;
  }
  .dropDown {
    position: relative;
    display: flex !important;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: none;
    padding: 10px;
  }
  
  .subNav {
    width: 100%;
  }
  
  .nv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nv span {
    font-size: 12px;
    margin-left: 5px;
  }
}
