@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 1.6; /* 22.4px */
    letter-spacing: 0.4px;
    color: #333333 !important; /* Adjust color as needed */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}

.__fw {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  height: auto !important;
}

.__mw {
  max-width: 1200px !important ;
  padding: 0px 2rem !important;
  display: flex !important;
  height: auto !important;
  width: 100% !important;
  // align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

li {
  font-size: 14px;
}
a {
  text-decoration: none;
}

hr {
  color: rgb(55, 55, 55);
  border: 0;
  border-top: 1px solid;
}
:root {
  --blue: #00afef;
  --brown: #f1563e;
  --bodr: #a358303a;
  --tBg: #da815121;
  --tran: 0.3s ease-in-out;
  --fontColor: #333333;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  line-height: 1.25;
  color: var(--fontColor);
}

h1,
.h1 {
  font-size: calc(1.475rem + 2.7vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 3.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.425rem + 2.1vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 3rem;
  }
}

h3,
.h3 {
  font-size: calc(1.355rem + 1.26vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 2.3rem;
  }
}

h4,
.h4 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.75rem;
  }
}

h5,
.h5 {
  font-size: calc(1.255rem + 0.06vw);
}

@media (min-width: 1200px) {
  h5,
  .h5 {
    font-size: 1.3rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
}

p {
   margin-bottom: 14px;

}

select {
  border: 1px solid var(--drkgray);
  border-radius: 5px;
  font-size: 14px !important;
  width: 100%;
  outline: none !important;
  padding: 0px 10px !important;
  // min-height: calc(1.25em + 1.6rem + 2px);
  height: 35px;
}
.thin {
  font-size: 1rem;
  font-weight: 400;
}

a,
Link {
  color: var(--darkColor);
}
::placeholder {
  font-family: "Poppins", sans-serif;
  // font-family: "Poppins", sans-serif;
  color: grey !important;
  font-size: 14px;
}
input:focus::placeholder {
  color: var(--bgcolor) !important;
}

select{
  background-color: rgba(128, 128, 128, 0.133);
  margin-bottom: 10px;
}
input{
  background-color: rgba(128, 128, 128, 0.133);
  margin-bottom: 10px;
  outline: none;
  border: none;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;

}
textarea{
  width: 100%;
   border-radius: 5px;
  padding: 10px 20px;

}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    width: 100%;
    margin-top: 10px;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    line-height: 1.5;
    background-color: var(--blue);
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}