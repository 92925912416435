.adv_wrp{
    width: 100%;
    padding: 2rem 0rem;
    h5{
        color: var(--blue);
    }
    .team_wrp {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  margin-bottom: 3rem;
  align-items: center;
  border-radius: 0.7rem;
  justify-content: center;

  .t_head {
    display: flex;
    margin-bottom: 20px;
    h4 {
      color: white;
    }
  }
  .team_in {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;

    .team_sec {
      max-width: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      .t_img {
        display: flex;
        overflow: hidden;
        .t_img_in {
          width: 130px;
          height: 130px;
          overflow: hidden;
          display: flex;
          border-radius: 100px;
        }
        img {
          width: 200px;
        }
      }
      .i_in {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding:0px 10px;
        text-align: center;
        h6 {
          color: var(--blue);
          margin-top: 10px;
        }
        span {
          color: black;
          font-size: 14px;
        }
      }
    }
  }
}


}