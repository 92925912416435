.reg_wrp{
    width: 100%;
  padding: 2rem 0rem;
  h5{
        color: var(--blue);
    }
    .reg_in{
        width: 100%;
        display: grid;
        grid-gap: 4rem;
        grid-template-columns: 3fr 1fr;
        .reg_left{
            width: 100%;
            display: flex;
            flex-direction: column;
            p{
                text-align: justify;
                em{
                    font-style: normal;
                    font-weight: 600;
                }
            }

        }
         .reg_right{
            width: 100%;
            display: flex;
            flex-direction: column;
            
        }

    }
    @media screen and (max-width:900px) {
        .reg_in{
            grid-template-columns: 1fr ;

        }
    }

}
ul{
    margin-left: 20px;
}